/// MODAL MESSAGES

export function modalMessage (text, type) {
	swal({
		text: text,
		type: type,
		animation: false,
		customClass: 'modal-alert',
		confirmButtonClass: 'btn btn-primary',
		buttonsStyling: false
	})
}

export function modalConfirm (text, type, cb) {
	swal({
	  text: text,
	  type: type,
	  animation: false,
	  showCancelButton: true,
	  buttonsStyling: false,
	  customClass: 'modal-alert',
	  cancelButtonClass: 'btn btn-danger',
	  cancelButtonText: 'Cancelar',
	  confirmButtonClass: 'btn btn-success',
	  confirmButtonText: 'Confirmar'
	}).then(function () {
		cb()
	})
}

/// LOADING EFECT

export function loading (param) {
	if(param){
		$('body').append('<div class="loading"><i class="fa fa-spinner fa-spin fa-4x"></i></div>')
	}
	else{
		$('.loading').remove()
	}
}

/// DOCUMENT PROFILE

export function documentProfile () {

	var url = URL_SITE + `document-profile.php?profile_id=${$('#profile_id').val()}`

	$.ajax({

		url: url,
		type: 'GET',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result){
			loading(false)
			$('[data-content="document-list"]').html(result)
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao consultar documentos', 'error')
			return
	    }
	})
}

/// PAYMENT PROFILE

export function paymentProfile () {

	var url = URL_SITE + `payment-profile.php?profile_id=${$('#profile_id').val()}`

	$.ajax({

		url: url,
		type: 'GET',
		beforeSend: function(request){
			// loading(true)
		},
		success: function(result){
			// loading(false)
			$('[data-content="payment-list"]').html(result)
			return
		},
		error: function (request, status, error) {
	        // loading(false)
			modalMessage('Erro ao consultar prazos', 'error')
			return
	    }
	})
}

/// SEARCH EMAIL DATA

export function searchEmailPj () {

	var url = URL_SITE + `email.php?email=${$('#email_contato1').val()}`

	$.ajax({

		url: url,
		type: 'GET',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result){
			console.log(result)
			loading(false)
			var obj = jQuery.parseJSON(result)
			if(obj['Erro']){
				modalMessage(obj['Erro'], 'warning')
			}
			else{
				modalMessage(obj['Mensagem'], 'success')
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao consultar e-mail', 'error')
			return
	    }
	})
}

export function searchEmailPf () {

	var url = URL_SITE + `email.php?email=${$('#email').val()}`

	$.ajax({

		url: url,
		type: 'GET',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result){
			console.log(result)
			loading(false)
			var obj = jQuery.parseJSON(result)
			if(obj['Erro']){
				modalMessage(obj['Erro'], 'warning')
			}
			else{
				modalMessage(obj['Mensagem'], 'success')
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao consultar e-mail', 'error')
			return
	    }
	})
}

/// SEARCH INFOBUSCA DATA

export function searchCpfInfobusca () {

	var url = URL_SITE + `infobusca.php?doc=${$('#cpf').val()}`

	$.ajax({

		url: url,
		type: 'GET',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result){
			console.log(result)
			loading(false)
			var obj = jQuery.parseJSON(result)
			if(obj['Erro']){
				modalMessage(obj['Erro'], 'warning')
				$('#nome').val('')
				$('#data_nascimento').val('')
				$('#sexo').val('')
			}
			else if(obj['SituacaoCadastral']['Situacao'] != 'REGULAR'){
				modalMessage('CPF não regular na Receita Federal', 'warning')
				$('#nome').val('')
				$('#data_nascimento').val('')
				$('#sexo').val('')
			}
			else{
				$('#nome').val(obj['DadosCadastrais']['Nome'])
				$('#data_nascimento').val(obj['DadosCadastrais']['Data_Nascimento'])
				$('#sexo').val(obj['DadosCadastrais']['Sexo'])
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao consultar CPF', 'error')
			return
	    }
	})
}

export function searchCnpjInfobusca () {

	var url = URL_SITE + `infobusca.php?doc=${$('#cnpj').val()}`

	$.ajax({

		url: url,
		type: 'GET',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result){
			console.log(result)
			loading(false)
			var obj = jQuery.parseJSON(result)
			if(obj['Erro']){
				modalMessage(obj['Erro'], 'warning')
				$('#inscricao_estadual').val('')
				$('#razao_social').val('')
				$('#nome_fantasia').val('')
				$('#cep_faturamento').val('')
				$('#ibge_faturamento').val('')
				$('#endereco_faturamento').val('')
				$('#numero_faturamento').val('')
				$('#complemento_faturamento').val('')
				$('#bairro_faturamento').val('')
				$('#cidade_faturamento').val('')
				$('#uf_faturamento').val('')
			}
			else if(obj['ReceitaFederal']['Situacao'] != 'ATIVA'){
				modalMessage('CNPJ não ativo na Receita Federal', 'warning')
				$('#inscricao_estadual').val('')
				$('#razao_social').val('')
				$('#nome_fantasia').val('')
				$('#cep_faturamento').val('')
				$('#ibge_faturamento').val('')
				$('#endereco_faturamento').val('')
				$('#numero_faturamento').val('')
				$('#complemento_faturamento').val('')
				$('#bairro_faturamento').val('')
				$('#cidade_faturamento').val('')
				$('#uf_faturamento').val('')
			}
			else if(obj['Sintegra']['Erro'] === undefined && obj['Sintegra']['situacao'] != undefined && obj['Sintegra']['situacao'] != 'ATIVO' && obj['Sintegra']['situacao'] != 'Ativo' && obj['Sintegra']['situacao'] != 'HABILITADO' && obj['Sintegra']['situacao'] != 'Habilitado'){
				modalMessage('CNPJ não ativo no Sintegra', 'warning')
				$('#inscricao_estadual').val('')
				$('#razao_social').val('')
				$('#nome_fantasia').val('')
				$('#cep_faturamento').val('')
				$('#ibge_faturamento').val('')
				$('#endereco_faturamento').val('')
				$('#numero_faturamento').val('')
				$('#complemento_faturamento').val('')
				$('#bairro_faturamento').val('')
				$('#cidade_faturamento').val('')
				$('#uf_faturamento').val('')
			}
			else{
				if(obj['Sintegra']['inscr_estadual'] != undefined){
					$('#inscricao_estadual').val(obj['Sintegra']['inscr_estadual'])
				}
				else{
					$('#inscricao_estadual').val('ISENTO')
				}
				$('#razao_social').val(obj['ReceitaFederal']['Nome'])
				if(obj['ReceitaFederal']['NomeFantasia'].length === undefined){
					$('#nome_fantasia').val(obj['ReceitaFederal']['Nome'])
				}
				else{
					$('#nome_fantasia').val(obj['ReceitaFederal']['NomeFantasia'])
				}
				$('#cep_faturamento').val(obj['ReceitaFederal']['CEP'])
				$('#ibge_faturamento').val(obj['IBGE']['CODUFIBGE'])
				$('#endereco_faturamento').val(obj['ReceitaFederal']['Logradouro'])
				$('#numero_faturamento').val(obj['ReceitaFederal']['Numero'])
				if(obj['ReceitaFederal']['Complemento'] != '[object Array]' && obj['ReceitaFederal']['Complemento'] != '[object Object]'){
					$('#complemento_faturamento').val(obj['ReceitaFederal']['Complemento'])
				}
				$('#bairro_faturamento').val(obj['ReceitaFederal']['BAIRRO'])
				$('#cidade_faturamento').val(obj['ReceitaFederal']['Municipio'])
				$('#uf_faturamento').val(obj['ReceitaFederal']['UF'])
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao consultar CNPJ', 'error')
			return
	    }
	})
}

/// SEARCH ADDRESS

export function searchHomeAddress () {

	var url = `https://viacep.com.br/ws/${$('#cep_residencia').val()}/json/`

	$.ajax({

		url: url,
		type: 'GET',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result){
			loading(false)
			$('#ibge_residencia').val(result['ibge'])
			$('#endereco_residencia').val(result['logradouro'])
			$('#numero_residencia').val('')
			$('#complemento_residencia').val('')
			$('#bairro_residencia').val(result['bairro'])
			$('#cidade_residencia').val(result['localidade'])
			$('#uf_residencia').val(result['uf'])
			if(result['erro']){
				modalMessage('CEP inválido', 'warning')
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao consultar CEP', 'error')
			return
	    }
	})
}

export function searchBillingAddress () {

	var url = `https://viacep.com.br/ws/${$('#cep_faturamento').val()}/json/`

	$.ajax({

		url: url,
		type: 'GET',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result){
			loading(false)
			$('#ibge_faturamento').val(result['ibge'])
			$('#endereco_faturamento').val(result['logradouro'])
			$('#numero_faturamento').val('')
			$('#complemento_faturamento').val('')
			$('#bairro_faturamento').val(result['bairro'])
			$('#cidade_faturamento').val(result['localidade'])
			$('#uf_faturamento').val(result['uf'])
			if(result['erro']){
				modalMessage('CEP inválido', 'warning')
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao consultar CEP', 'error')
			return
	    }
	})
}

export function searchShippingAddress () {

	var url = `https://viacep.com.br/ws/${$('#cep_entrega').val()}/json/`

	$.ajax({

		url: url,
		type: 'GET',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result){
			loading(false)
			$('#ibge_entrega').val(result['ibge'])
			$('#endereco_entrega').val(result['logradouro'])
			$('#numero_entrega').val('')
			$('#complemento_entrega').val('')
			$('#bairro_entrega').val(result['bairro'])
			$('#cidade_entrega').val(result['localidade'])
			$('#uf_entrega').val(result['uf'])
			if(result['erro']){
				modalMessage('CEP inválido', 'warning')
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao consultar CEP', 'error')
			return
	    }
	})
}

/// LOGIN AND LOGOUT

export function login (form) {

	var data = `action=login&${$(form).serialize()}`

	$.ajax({

		url: 'controller.php',
		data: data,
		type: 'POST',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result){
			if(result == 200){
				window.location.href = 'customer-list.php'
			}
			else if(result == 401){
				loading(false)
				modalMessage('Login ou senha incorretos', 'error')
			}
			else{
				loading(false)
				modalMessage('Erro ao realizar login', 'error')
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao realizar login', 'error')
			return
	    }
	})
}

export function logout () {

	var data = `action=logout`

	$.ajax({

		url: 'controller.php',
		data: data,
		type: 'POST',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result){
			window.location.href = 'index.php'
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao realizar logout', 'error')
			return
	    }
	})
}

/// CHECKLIST FUNCTIONS

export function deleteChecklist(){

	var data = {};

	data['id'] = $(this).attr('data-id')
    data['action'] = 'delete';

	modalConfirm('Confirma exclusão do checklist?', 'question', function(){

		$.ajax({

			url: 'checklist-list.php',
			data: data,
			type: 'POST',
			beforeSend: function(request){
				loading(true)
			},
			success: function(result, text, xhr){
				console.log(result)
				if(xhr.status === 200){
					window.location.href = 'checklist-list.php'
				}
				else{
					loading(false)
					modalMessage('Erro ao excluir checklist', 'error')
				}
				return
			},
			error: function (request, status, error) {
		        loading(false)
				modalMessage('Erro ao excluir checklist', 'error')
				return
		    }
		})
	})
}

export function saveChecklist(form){

	var data = $(form).serializeArray()

    $.ajax({

		url: 'checklist-form.php',
		data: data,
		type: 'POST',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result, text, xhr){
			if(xhr.status === 200 || xhr.status === 201){
				var result = $.parseJSON(result);
				if(result['error']){
					loading(false)
					$.each(result['error'], function(index, value) {
						modalMessage(value, 'warning')
					});
				}
				else{
					window.location.href = 'checklist-list.php'
				}
			}
			else{
				loading(false)
				modalMessage('Erro ao cadastrar checklist', 'error')
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao cadastrar checklist', 'error')
			return
	    }
	})
}

/// CUSTOMER FUNCTIONS

export function deleteCustomer(){

	var data = {};

	data['id'] = $(this).attr('data-id')
    data['action'] = 'delete';

	modalConfirm('Confirma exclusão do cliente?', 'question', function(){

		$.ajax({

			url: 'customer-list.php',
			data: data,
			type: 'POST',
			beforeSend: function(request){
				loading(true)
			},
			success: function(result, text, xhr){
				if(xhr.status === 200){
					window.location.href = 'customer-list.php'
				}
				else{
					loading(false)
					modalMessage('Erro ao excluir cliente', 'error')
				}
				return
			},
			error: function (request, status, error) {
		        loading(false)
				modalMessage('Erro ao excluir cliente', 'error')
				return
		    }
		})
	})
}

export function saveCustomerPj(form){

	var data = $(form).serializeArray()
    var s = '';
    var stores = 0;

    for(s in data){
    	if(data[s]['name'].indexOf('store-') === 0){
    		stores++;
    	}
    }

    if(stores === 0){
    	modalMessage('Selecione as filiais do cliente', 'warning')
    	return
    }

    $.ajax({

		url: 'customer-form-pj.php',
		data: data,
		type: 'POST',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result, text, xhr){
			if(xhr.status === 200 || xhr.status === 201){
				var result = $.parseJSON(result);
				if(result['error']){
					loading(false)
					$.each(result['error'], function(index, value) {
						modalMessage(value, 'warning')
					});
				}
				else{
					if($('#ecommerce').val() == '1'){
						window.location.href = 'customer-list.php'
					}
					else{
						window.location.href = 'customer-limit.php?id=' + result['id']
					}
				}
			}
			else{
				loading(false)
				modalMessage('Erro ao cadastrar cliente', 'error')
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao cadastrar cliente', 'error')
			return
	    }
	})
}

export function saveCustomerPf(form){

	var data = $(form).serializeArray()
    var s = '';
    var stores = 0;

    for(s in data){
    	if(data[s]['name'].indexOf('store-') === 0){
    		stores++;
    	}
    }

    if(stores === 0){
    	modalMessage('Selecione as filiais do cliente', 'warning')
    	return
    }

    $.ajax({

		url: 'customer-form-pf.php',
		data: data,
		type: 'POST',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result, text, xhr){
			if(xhr.status === 200 || xhr.status === 201){
				var result = $.parseJSON(result);
				if(result['error']){
					loading(false)
					$.each(result['error'], function(index, value) {
						modalMessage(value, 'warning')
					});
				}
				else{
					if($('#ecommerce').val() == '1'){
						window.location.href = 'customer-list.php'
					}
					else{
						window.location.href = 'customer-limit.php?id=' + result['id']
					}
				}
			}
			else{
				loading(false)
				modalMessage('Erro ao cadastrar cliente', 'error')
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao cadastrar cliente', 'error')
			return
	    }
	})
}

export function saveCustomerLimit(form){

	var data = $(form).serializeArray()
    var s = '';
    var limiteerr = false

    for(s in data){
    	if(data[s]['name'].indexOf('limite-de-') > -1){
    		var limite_de = data[s]['value'].replace('.', '').replace(',', '.')
    	}
    	else if(data[s]['name'].indexOf('limite-ate-') > -1){
    		var limite_ate = data[s]['value'].replace('.', '').replace(',', '.')
    	}
    	else if(data[s]['name'].indexOf('limite-') > -1){
    		var limite = data[s]['value'].replace('.', '').replace(',', '.')
    		if (Math.round(limite * 100) < Math.round(limite_de * 100)){
    			$('#'+data[s]['name']).closest('.form-group').addClass('has-error')
				limiteerr = true
    		}
    		else if (Math.round(limite * 100) > Math.round(limite_ate * 100)){
    			$('#'+data[s]['name']).closest('.form-group').addClass('has-error')
				limiteerr = true
    		}
    		else{
    			$('#'+data[s]['name']).closest('.form-group').removeClass('has-error')
    		}
    	}
    }

    if(limiteerr == true){
    	loading(false)
		modalMessage('Limite inválido', 'warning')
		return
    }

    $.ajax({

		url: 'customer-limit.php',
		data: data,
		type: 'POST',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result, text, xhr){
			if(xhr.status === 200 || xhr.status === 201){
				window.location.href = 'customer-list.php'
			}
			else{
				loading(false)
				modalMessage('Erro ao alterar cliente', 'error')
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao alterar cliente', 'error')
			return
	    }
	})
}

export function saveCustomerDetailPj(form){

	var data = $(form).serializeArray()

    $.ajax({

		url: 'customer-detail-pj.php',
		data: data,
		type: 'POST',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result, text, xhr){

			if(xhr.status === 200 || xhr.status === 201){
				window.location.href = 'customer-list.php'
			}
			else{
				loading(false)
				modalMessage('Erro ao alterar cliente', 'error')
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao alterar cliente', 'error')
			return
	    }
	})
}

export function saveCustomerDetailPf(form){

	var data = $(form).serializeArray()

    $.ajax({

		url: 'customer-detail-pf.php',
		data: data,
		type: 'POST',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result, text, xhr){

			if(xhr.status === 200 || xhr.status === 201){
				window.location.href = 'customer-list.php'
			}
			else{
				loading(false)
				modalMessage('Erro ao alterar cliente', 'error')
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao alterar cliente', 'error')
			return
	    }
	})
}

/// DOCUMENT FUNCTIONS

export function deleteDocument(){

	var data = {};

	data['id'] = $(this).attr('data-id')
    data['action'] = 'delete';

	modalConfirm('Confirma exclusão do documento?', 'question', function(){

		$.ajax({

			url: 'document-list.php',
			data: data,
			type: 'POST',
			beforeSend: function(request){
				loading(true)
			},
			success: function(result, text, xhr){
				if(xhr.status === 200){
					window.location.href = 'document-list.php'
				}
				else{
					loading(false)
					modalMessage('Erro ao excluir documento', 'error')
				}
				return
			},
			error: function (request, status, error) {
		        loading(false)
				modalMessage('Erro ao excluir documento', 'error')
				return
		    }
		})
	})
}

export function saveDocument(form){

	var data = $(form).serializeArray()

    $.ajax({

		url: 'document-form.php',
		data: data,
		type: 'POST',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result, text, xhr){
			if(xhr.status === 200 || xhr.status === 201){
				var result = $.parseJSON(result);
				if(result['error']){
					loading(false)
					$.each(result['error'], function(index, value) {
						modalMessage(value, 'warning')
					});
				}
				else{
					window.location.href = 'document-list.php'
				}
			}
			else{
				loading(false)
				modalMessage('Erro ao cadastrar documento', 'error')
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao cadastrar documento', 'error')
			return
	    }
	})
}

/// PAYMENT FUNCTIONS

export function deletePayment(){

	var data = {};

	data['id'] = $(this).attr('data-id')
    data['action'] = 'delete';

	modalConfirm('Confirma exclusão do prazo?', 'question', function(){

		$.ajax({

			url: 'payment-list.php',
			data: data,
			type: 'POST',
			beforeSend: function(request){
				loading(true)
			},
			success: function(result, text, xhr){
				if(xhr.status === 200){
					window.location.href = 'payment-list.php'
				}
				else{
					loading(false)
					modalMessage('Erro ao excluir prazo', 'error')
				}
				return
			},
			error: function (request, status, error) {
		        loading(false)
				modalMessage('Erro ao excluir prazo', 'error')
				return
		    }
		})
	})
}

export function savePayment(form){

	var data = $(form).serializeArray()

    $.ajax({

		url: 'payment-form.php',
		data: data,
		type: 'POST',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result, text, xhr){
			if(xhr.status === 200 || xhr.status === 201){
				var result = $.parseJSON(result);
				if(result['error']){
					loading(false)
					$.each(result['error'], function(index, value) {
						modalMessage(value, 'warning')
					});
				}
				else{
					window.location.href = 'payment-list.php'
				}
			}
			else{
				loading(false)
				modalMessage('Erro ao cadastrar prazo', 'error')
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao cadastrar prazo', 'error')
			return
	    }
	})
}

/// PROFILE FUNCTIONS

export function deleteProfile(){

	var data = {};

	data['id'] = $(this).attr('data-id')
    data['action'] = 'delete';

	modalConfirm('Confirma exclusão do perfil?', 'question', function(){

		$.ajax({

			url: 'profile-list.php',
			data: data,
			type: 'POST',
			beforeSend: function(request){
				loading(true)
			},
			success: function(result, text, xhr){
				if(xhr.status === 200){
					window.location.href = 'profile-list.php'
				}
				else{
					loading(false)
					modalMessage('Erro ao excluir perfil', 'error')
				}
				return
			},
			error: function (request, status, error) {
		        loading(false)
				modalMessage('Erro ao excluir perfil', 'error')
				return
		    }
		})
	})
}

export function saveProfile(form){

	var data = $(form).serializeArray()

    $.ajax({

		url: 'profile-form.php',
		data: data,
		type: 'POST',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result, text, xhr){
			if(xhr.status === 200 || xhr.status === 201){
				var result = $.parseJSON(result);
				if(result['error']){
					loading(false)
					$.each(result['error'], function(index, value) {
						modalMessage(value, 'warning')
					});
				}
				else{
					window.location.href = 'profile-list.php'
				}
			}
			else{
				loading(false)
				modalMessage('Erro ao cadastrar perfil', 'error')
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao cadastrar perfil', 'error')
			return
	    }
	})
}

/// SEGMENTS FUNCTIONS

export function deleteSegment(){

	var data = {};

	data['id'] = $(this).attr('data-id')
    data['action'] = 'delete';

	modalConfirm('Confirma exclusão do segmento?', 'question', function(){

		$.ajax({

			url: 'segment-list.php',
			data: data,
			type: 'POST',
			beforeSend: function(request){
				loading(true)
			},
			success: function(result, text, xhr){
				if(xhr.status === 200){
					window.location.href = 'segment-list.php'
				}
				else{
					loading(false)
					modalMessage('Erro ao excluir segmento', 'error')
				}
				return
			},
			error: function (request, status, error) {
		        loading(false)
				modalMessage('Erro ao excluir segmento', 'error')
				return
		    }
		})
	})
}

export function saveSegment(form){

	var data = $(form).serializeArray()

    $.ajax({

		url: 'segment-form.php',
		data: data,
		type: 'POST',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result, text, xhr){
			if(xhr.status === 200 || xhr.status === 201){
				var result = $.parseJSON(result);
				if(result['error']){
					loading(false)
					$.each(result['error'], function(index, value) {
						modalMessage(value, 'warning')
					});
				}
				else{
					window.location.href = 'segment-list.php'
				}
			}
			else{
				loading(false)
				modalMessage('Erro ao cadastrar segmento', 'error')
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao cadastrar segmento', 'error')
			return
	    }
	})
}

/// STORE FUNCTIONS

export function deleteStore(){

	var data = {};

	data['id'] = $(this).attr('data-id')
    data['action'] = 'delete';

	modalConfirm('Confirma exclusão da filial?', 'question', function(){

		$.ajax({

			url: 'store-list.php',
			data: data,
			type: 'POST',
			beforeSend: function(request){
				loading(true)
			},
			success: function(result, text, xhr){
				if(xhr.status === 200){
					window.location.href = 'store-list.php'
				}
				else{
					loading(false)
					modalMessage('Erro ao excluir filial', 'error')
				}
				return
			},
			error: function (request, status, error) {
		        loading(false)
				modalMessage('Erro ao excluir filial', 'error')
				return
		    }
		})
	})
}

export function saveStore(form){

	var data = $(form).serializeArray()

    $.ajax({

		url: 'store-form.php',
		data: data,
		type: 'POST',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result, text, xhr){
			if(xhr.status === 200 || xhr.status === 201){
				var result = $.parseJSON(result);
				if(result['error']){
					loading(false)
					$.each(result['error'], function(index, value) {
						modalMessage(value, 'warning')
					});
				}
				else{
					window.location.href = 'store-list.php'
				}
			}
			else{
				loading(false)
				modalMessage('Erro ao cadastrar filial', 'error')
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao cadastrar filial', 'error')
			return
	    }
	})
}

/// USER FUNCTIONS

export function deleteUser(){

	var data = {};

	data['id'] = $(this).attr('data-id')
    data['action'] = 'delete';

	modalConfirm('Confirma exclusão do usuário?', 'question', function(){

		$.ajax({

			url: 'user-list.php',
			data: data,
			type: 'POST',
			beforeSend: function(request){
				loading(true)
			},
			success: function(result, text, xhr){
				if(xhr.status === 200){
					window.location.href = 'user-list.php'
				}
				else{
					loading(false)
					modalMessage('Erro ao excluir usuário', 'error')
				}
				return
			},
			error: function (request, status, error) {
		        loading(false)
				modalMessage('Erro ao excluir usuário', 'error')
				return
		    }
		})
	})
}

export function saveUser(form){

	var data = $(form).serializeArray()

    $.ajax({

		url: 'user-form.php',
		data: data,
		type: 'POST',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result, text, xhr){
			if(xhr.status === 200 || xhr.status === 201){
				var result = $.parseJSON(result);
				if(result['error']){
					loading(false)
					$.each(result['error'], function(index, value) {
						modalMessage(value, 'warning')
					});
				}
				else{
					window.location.href = 'user-list.php'
				}
			}
			else{
				loading(false)
				modalMessage('Erro ao cadastrar usuário', 'error')
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao cadastrar usuário', 'error')
			return
	    }
	})
}

export function saveUserPassword(form){

	var data = $(form).serializeArray()

    $.ajax({

		url: 'user-form-password.php',
		data: data,
		type: 'POST',
		beforeSend: function(request){
			loading(true)
		},
		success: function(result, text, xhr){
			if(xhr.status === 200 || xhr.status === 201){
				loading(false)
				modalMessage('Senha alterada com sucesso', 'success')
			}
			else{
				loading(false)
				modalMessage('Erro ao alterar senha do usuário', 'error')
			}
			return
		},
		error: function (request, status, error) {
	        loading(false)
			modalMessage('Erro ao alterar senha do usuário', 'error')
			return
	    }
	})
}
