import * as FUNCTIONS from './functions.js'

/// CLICK, BLUR AND CHANGE LISTENERS

$('body').on('click', '[data-click="logout"]', FUNCTIONS.logout)

$('body').on('click', '[data-click="delete_checklist"]', FUNCTIONS.deleteChecklist)

$('body').on('click', '[data-click="delete_customer"]', FUNCTIONS.deleteCustomer)

$('body').on('click', '[data-click="delete_document"]', FUNCTIONS.deleteDocument)

$('body').on('click', '[data-click="delete_payment"]', FUNCTIONS.deletePayment)

$('body').on('click', '[data-click="delete_profile"]', FUNCTIONS.deleteProfile)

$('body').on('click', '[data-click="delete_segment"]', FUNCTIONS.deleteSegment)

$('body').on('click', '[data-click="delete_store"]', FUNCTIONS.deleteStore)

$('body').on('click', '[data-click="delete_user"]', FUNCTIONS.deleteUser)

$('body').on('click', '[data-click="search_home_address"]', FUNCTIONS.searchHomeAddress)

$('body').on('click', '[data-click="search_billing_address"]', FUNCTIONS.searchBillingAddress)

$('body').on('click', '[data-click="search_shipping_address"]', FUNCTIONS.searchShippingAddress)

$('body').on('click', '[data-click="search_cpf_infobusca"]', FUNCTIONS.searchCpfInfobusca)

$('body').on('click', '[data-click="search_cnpj_infobusca"]', FUNCTIONS.searchCnpjInfobusca)

$('body').on('click', '[data-click="search_email_pj"]', FUNCTIONS.searchEmailPj)

$('body').on('click', '[data-click="search_email_pf"]', FUNCTIONS.searchEmailPf)

$('body').on('change', '[data-change2="payment_profile"]', FUNCTIONS.paymentProfile)

$('body').on('change', '[data-change="document_profile"]', FUNCTIONS.documentProfile)

/// SUBMIT BUTTONS LISTENER

$('body').on('click', 'a[data-click="search_customer"]', function() {
	$('[data-form="customer-list"]').submit()
})

$('body').on('click', 'a[data-click="search_user"]', function() {
	$('[data-form="user-list"]').submit()
})

$('body').on('click', '[type="submit"]', function() {

	$(this).parents('form').validate({
		errorClass: 'text-danger error',
		errorElement: 'small',
		highlight (element) {
			$(element).closest('.form-group').addClass('has-error')
		},
		success: function (label, element) {
			$(element).closest('.form-group').removeClass('has-error')
		},
		submitHandler (form) {

			if ($(form).attr('data-form') === 'checklist'){
				FUNCTIONS.saveChecklist(form)
			}
			else if ($(form).attr('data-form') === 'customer-list'){
				FUNCTIONS.saveCustomer(form)
			}
			else if ($(form).attr('data-form') === 'customer-pj'){
				FUNCTIONS.saveCustomerPj(form)
			}
			else if ($(form).attr('data-form') === 'customer-pf'){
				FUNCTIONS.saveCustomerPf(form)
			}
			else if ($(form).attr('data-form') === 'customer-limit'){
				FUNCTIONS.saveCustomerLimit(form)
			}
			else if ($(form).attr('data-form') === 'customer-detail-pj'){
				FUNCTIONS.saveCustomerDetailPj(form)
			}
			else if ($(form).attr('data-form') === 'customer-detail-pf'){
				FUNCTIONS.saveCustomerDetailPf(form)
			}
			else if ($(form).attr('data-form') === 'document'){
				FUNCTIONS.saveDocument(form)
			}
			else if ($(form).attr('data-form') === 'payment'){
				FUNCTIONS.savePayment(form)
			}
			else if ($(form).attr('data-form') === 'login'){
				FUNCTIONS.login(form)
			}
			else if ($(form).attr('data-form') === 'profile'){
				FUNCTIONS.saveProfile(form)
			}
			else if ($(form).attr('data-form') === 'segment'){
				FUNCTIONS.saveSegment(form)
			}
			else if ($(form).attr('data-form') === 'store'){
				FUNCTIONS.saveStore(form)
			}
			else if ($(form).attr('data-form') === 'user'){
				FUNCTIONS.saveUser(form)
			}
			else if ($(form).attr('data-form') === 'user-password'){
				FUNCTIONS.saveUserPassword(form)
			}
		}
	})
})

//////////////// SHOW / HIDE PANELS ////////////////////////

$('body').on('click', 'input[data-type="novo_endereco"]', function() {
	$('[data-content="novo_endereco"]').removeClass('hidden')
})

$('body').on('click', 'input[data-type="mesmo_endereco"]', function() {
	$('[data-content="novo_endereco"]').addClass('hidden')
})

$('body').on('change', '[data-select="user-profile"]', function() {
	if($('[data-select="user-profile"]').val() == '1'){
		$('[data-content="gerente"]').removeClass('hidden')
		$('[data-content="supervisor"]').removeClass('hidden')
		$('[data-content="ecommerce"]').removeClass('hidden')
	}
	else{
		$('[data-content="gerente"]').addClass('hidden')
		$('[data-content="supervisor"]').addClass('hidden')
		$('[data-content="ecommerce"]').addClass('hidden')
	}
})

$('body').on('change', '[data-select="profile-ecommerce"]', function() {

	if($('[data-select="profile-ecommerce"]').val() == ''){
		$('[data-content="limites"]').addClass('hidden')
		$('[data-content="limite-mensal"]').addClass('hidden')
		$('[data-content="limite-semanal"]').addClass('hidden')
	}
	else if($('[data-select="profile-ecommerce"]').val() == '1'){
		$('[data-content="limites"]').addClass('hidden')
		$('[data-content="limite-mensal"]').removeClass('hidden')
		$('[data-content="limite-semanal"]').removeClass('hidden')
	}
	else if($('[data-select="profile-ecommerce"]').val() == '0'){
		$('[data-content="limites"]').removeClass('hidden')
		$('[data-content="limite-mensal"]').addClass('hidden')
		$('[data-content="limite-semanal"]').addClass('hidden')
	}
})

//////////////// TOOLTIP ////////////////////////

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})


