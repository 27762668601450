/// VALIDACAO DE CNPJ

$.validator.addMethod( 'cnpjBR', function( value ) {

	value = value.replace(/[^\d]+/g,'')

	if(value == '') return false

	if (value.length != 14)
		return false

	// Elimina cnpjs invalidos conhecidos
	if (value == '00000000000000' ||
		value == '11111111111111' ||
		value == '22222222222222' ||
		value == '33333333333333' ||
		value == '44444444444444' ||
		value == '55555555555555' ||
		value == '66666666666666' ||
		value == '77777777777777' ||
		value == '88888888888888' ||
		value == '99999999999999')
		return false

	// Valida DVs
	let tamanho = value.length - 2
	let numeros = value.substring(0,tamanho)
	let digitos = value.substring(tamanho)
	let soma = 0
	let pos = tamanho - 7
	for (let i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--
		if (pos < 2)
			pos = 9
	}
	let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
	if (resultado != digitos.charAt(0))
		return false

	tamanho = tamanho + 1
	numeros = value.substring(0,tamanho)
	soma = 0
	pos = tamanho - 7
	for (let i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--
		if (pos < 2)
			pos = 9
	}
	resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
	if (resultado != digitos.charAt(1))
		return false

	return true

}, 'Por favor, forneça um CNPJ válido.' )

/// TRADUCAO DE MENSAGENS DO VALIDATOR

$.extend( $.validator.messages, {
	// Core
	required: 'Este campo &eacute; requerido.',
	remote: 'Por favor, corrija este campo.',
	email: 'Por favor, forne&ccedil;a um endere&ccedil;o de email v&aacute;lido.',
	url: 'Por favor, forne&ccedil;a uma URL v&aacute;lida.',
	date: 'Por favor, forne&ccedil;a uma data v&aacute;lida.',
	dateISO: 'Por favor, forne&ccedil;a uma data v&aacute;lida (ISO).',
	number: 'Por favor, forne&ccedil;a um n&uacute;mero v&aacute;lido.',
	digits: 'Por favor, forne&ccedil;a somente d&iacute;gitos.',
	creditcard: 'Por favor, forne&ccedil;a um cart&atilde;o de cr&eacute;dito v&aacute;lido.',
	equalTo: 'Por favor, forne&ccedil;a o mesmo valor novamente.',
	maxlength: $.validator.format( 'Por favor, forne&ccedil;a n&atilde;o mais que {0} caracteres.' ),
	minlength: $.validator.format( 'Por favor, forne&ccedil;a ao menos {0} caracteres.' ),
	rangelength: $.validator.format( 'Por favor, forne&ccedil;a um valor entre {0} e {1} caracteres de comprimento.' ),
	range: $.validator.format( 'Por favor, forne&ccedil;a um valor entre {0} e {1}.' ),
	max: $.validator.format( 'Por favor, forne&ccedil;a um valor menor ou igual a {0}.' ),
	min: $.validator.format( 'Por favor, forne&ccedil;a um valor maior ou igual a {0}.' ),
	step: $.validator.format( 'Por favor, forne&ccedil;a um valor m&acute;tiplo de {0}.' ),

	// Metodos Adicionais
	maxWords: $.validator.format( 'Por favor, forne&ccedil;a com {0} palavras ou menos.' ),
	minWords: $.validator.format( 'Por favor, forne&ccedil;a pelo menos {0} palavras.' ),
	rangeWords: $.validator.format( 'Por favor, forne&ccedil;a entre {0} e {1} palavras.' ),
	accept: 'Por favor, forne&ccedil;a um tipo v&aacute;lido.',
	alphanumeric: 'Por favor, forne&ccedil;a somente com letras, n&uacute;meros e sublinhados.',
	bankaccountNL: 'Por favor, forne&ccedil;a com um n&uacute;mero de conta banc&aacute;ria v&aacute;lida.',
	bankorgiroaccountNL: 'Por favor, forne&ccedil;a um banco v&aacute;lido ou n&uacute;mero de conta.',
	bic: 'Por favor, forne&ccedil;a um c&oacute;digo BIC v&aacute;lido.',
	cifES: 'Por favor, forne&ccedil;a um c&oacute;digo CIF v&aacute;lido.',
	creditcardtypes: 'Por favor, forne&ccedil;a um n&uacute;mero de cart&atilde;o de cr&eacute;dito v&aacute;lido.',
	currency: 'Por favor, forne&ccedil;a uma moeda v&aacute;lida.',
	dateFA: 'Por favor, forne&ccedil;a uma data correta.',
	dateITA: 'Por favor, forne&ccedil;a uma data correta.',
	dateNL: 'Por favor, forne&ccedil;a uma data correta.',
	extension: 'Por favor, forne&ccedil;a um valor com uma extens&atilde;o v&aacute;lida.',
	giroaccountNL: 'Por favor, forne&ccedil;a um n&uacute;mero de conta corrente v&aacute;lido.',
	iban: 'Por favor, forne&ccedil;a um c&oacute;digo IBAN v&aacute;lido.',
	integer: 'Por favor, forne&ccedil;a um n&uacute;mero n&atilde;o decimal.',
	ipv4: 'Por favor, forne&ccedil;a um IPv4 v&aacute;lido.',
	ipv6: 'Por favor, forne&ccedil;a um IPv6 v&aacute;lido.',
	lettersonly: 'Por favor, forne&ccedil;a apenas com letras.',
	letterswithbasicpunc: 'Por favor, forne&ccedil;a apenas letras ou pontua&ccedil;ões.',
	mobileNL: 'Por favor, fornece&ccedil;a um n&uacute;mero v&aacute;lido de telefone.',
	mobileUK: 'Por favor, fornece&ccedil;a um n&uacute;mero v&aacute;lido de telefone.',
	nieES: 'Por favor, forne&ccedil;a um NIE v&aacute;lido.',
	nifES: 'Por favor, forne&ccedil;a um NIF v&aacute;lido.',
	nowhitespace: 'Por favor, n&atilde;o utilize espa&ccedil;os em branco.',
	pattern: 'O formato fornenecido &eacute; inv&aacute;lido.',
	phoneNL: 'Por favor, fornece&ccedil;a um n&uacute;mero de telefone v&aacute;lido.',
	phoneUK: 'Por favor, fornece&ccedil;a um n&uacute;mero de telefone v&aacute;lido.',
	phoneUS: 'Por favor, fornece&ccedil;a um n&uacute;mero de telefone v&aacute;lido.',
	phonesUK: 'Por favor, fornece&ccedil;a um n&uacute;mero de telefone v&aacute;lido.',
	postalCodeCA: 'Por favor, fornece&ccedil;a um n&uacute;mero de c&oacute;digo postal v&aacute;lido.',
	postalcodeIT: 'Por favor, fornece&ccedil;a um n&uacute;mero de c&oacute;digo postal v&aacute;lido.',
	postalcodeNL: 'Por favor, fornece&ccedil;a um n&uacute;mero de c&oacute;digo postal v&aacute;lido.',
	postcodeUK: 'Por favor, fornece&ccedil;a um n&uacute;mero de c&oacute;digo postal v&aacute;lido.',
	postalcodeBR: 'Por favor, forne&ccedil;a um CEP v&aacute;lido.',
	require_from_group: $.validator.format( 'Por favor, forne&ccedil;a pelo menos {0} destes campos.' ),
	skip_or_fill_minimum: $.validator.format( 'Por favor, optar entre ignorar esses campos ou preencher pelo menos {0} deles.' ),
	stateUS: 'Por favor, forne&ccedil;a um estado v&aacute;lido.',
	strippedminlength: $.validator.format( 'Por favor, forne&ccedil;a pelo menos {0} caracteres.' ),
	time: 'Por favor, forne&ccedil;a um hor&aacute;rio v&aacute;lido, no intervado de 00:00 e 23:59.',
	time12h: 'Por favor, forne&ccedil;a um hor&aacute;rio v&aacute;lido, no intervado de 01:00 e 12:59 am/pm.',
	url2: 'Por favor, fornece&ccedil;a uma URL v&aacute;lida.',
	vinUS: 'O n&uacute;mero de identifica&ccedil;&atilde;o de ve&iacute;culo informada (VIN) &eacute; inv&aacute;lido.',
	zipcodeUS: 'Por favor, fornece&ccedil;a um c&oacute;digo postal americano v&aacute;lido.',
	ziprange: 'O c&oacute;digo postal deve estar entre 902xx-xxxx e 905xx-xxxx',
	cpfBR: 'Por favor, forne&ccedil;a um CPF v&aacute;lido.'
})
