function overflowEvent (callback) {
	$('body').append('<div class="overflow"></div>')
	$('.overflow').on('click', function(){
		callback()
		$(this).remove()
	})
}

$('body').on('click', '[data-toggle="menu"]', function() {
	$('.menu-sidebar').toggleClass('active')
	overflowEvent(() => $('.menu-sidebar').toggleClass('active'))
})
